import React from "react"
import { Link } from "gatsby"
import '../styles/utilitary.css'
import '../styles/hamb-salmao.css'

import Layout from "../components/layout"
import SEO from "../components/seo"
import logo from "../images/logo.png" // Tell webpack this JS file uses this image
import atumcover from "../images/salmao-cover.png" // Tell webpack this JS file uses this image
import atumicones from "../images/atum-icones.png" // Tell webpack this JS file uses this image
import atumicones2 from "../images/atum-icones2.png" // Tell webpack this JS file uses this image
import salmaoicones2 from "../images/salmao-icones2.png" // Tell webpack this JS file uses this image
import salmaoicones from "../images/salmao-icones.png" // Tell webpack this JS file uses this image
import logonew from "../images/logo-new-salmon.png" // Tell webpack this JS file uses this image
import $ from 'jquery' // important: case sensitive.
import scrollTo from 'gatsby-plugin-smoothscroll';
import StopMotion from "../images/StopMotion.mp4"


function clickHandler(){
  $("#modal1").css("display", "block");

  $("#modal1").css("opacity", "1");
}
function clickHandler2(){
  $("#modal2").css("display", "block");

  $("#modal2").css("opacity", "1");
}
function clickHandler3(){
  $("#modal3").css("display", "block");

  $("#modal3").css("opacity", "1");
}
function closeHandler(){
 
  $("#modal1").css("opacity", "0");
  $("#modal1").css("display", "none");

}

function closeHandler2(){
  $("#modal2").css("opacity", "0");
  $("#modal2").css("display", "none");


}
function closeHandler3(){
  $("#modal3").css("opacity", "0");
  $("#modal3").css("display", "none");


}



const hamburguerBacalhau = () => (
  <Layout>
    <SEO title="Peixe Fácil - Hambúrguer de Salmão Chili-Lemon" />
    <div id="modal1" className="modal">
      
      <div className="modal-container">
        <div className="header-modal">
          <div className="left-col">
          <p className="titulo absara">Fonte de Proteína</p>
          </div>
          <div className="right-col">

          <button className="titulo blue-marine sneak-regular" onClick={closeHandler}>Fechar</button>
          </div>
        </div>
        <div className="body-modal">
           <p className="descricao sneak-regular">
           A proteina é um dos macronutrientes que compõem a dieta alimentar. É principalmente encontrada em alimentos de origem animal apesar de também estar presente noutras fontes à base de plantas como legumes, nozes e sementes. Uma dieta equilibrada deve assegurar uma adequada ingestão de proteina assim como de aminoácidos essenciais.<br></br><br></br> A proteina tem um papel fundamental na:<br></br><br></br>
<b>• Manutenção de massa muscular;</b><br></br>
<b>• Crescimento, reparação e manutenção de células;</b><br></br>
<b>• Reações metabólicas;</b><br></br>
<b>• Resposta imunitária.</b><br></br><br></br>
Uma dieta rica em proteina pode beneficiar a rápida recuperação após o exercício, ajudar na manutenção de peso e sensação de maior saciedade.
           </p>
        </div>
      </div>

    </div>

    <div id="modal2" className="modal">
      
      <div className="modal-container">
        <div className="header-modal">
          <div className="left-col">
          <p className="titulo blue-marine absara">Alto teor em vitamina D e B12</p>
          </div>
          <div className="right-col">

          <button className="titulo sneak-regular" onClick={closeHandler2}>Fechar</button>
          </div>
        </div>
        <div className="body-modal">
           <p className="descricao sneak-regular">
           A síntese de vitamina D é essencialmente realizada pela pele, através da exposição solar. Nos meses de menor exposição solar deverá ser colmatada com uma maior ingestão de alimentos ricos em vitamina D. As fontes naturais de vitamina D na alimentação encontram-se principalmente presentes em peixes como atum, dourada, salmão, robalo e pescada.<br></br><br></br>A vitamina D tem um papel crítico na:<br></br><br></br>
           <b>• Função imunitária;</b><br></br>
           <b>• Na prevenção de infeções respiratórias;</b><br></br>
           <b>• Está associada ao fortalecimento muscular, uma vez que participa no processo de formação dos músculos.</b><br></br>
           <br></br><br></br>
           Também conhecida como Cobalamina, a Vitamina B12 é uma vitamina essencial ao organismo que não sendo produzida pelo nosso corpo há necessidade de ser ingerida através da alimentação. A única fonte natural de Vitamina B12 são alimentos de origem animal e esta vitamina apresenta funções importantes no nosso organismo como:
           <br></br><br></br><b>• Auxiliar na normal função de células nervosas;</b><br></br>
<b>• Síntese de material genético;</b><br></br>
<b>• Formação de células sanguíneas;</b><br></br>
<b>• Papel importante na manutenção de ossos saudáveis.</b><br></br>
           </p>
        </div>
      </div>

    </div>


    <div id="modal3" className="modal">
      
      <div className="modal-container">
        <div className="header-modal">
          <div className="left-col">
          <p className="titulo blue-marine absara">Alto teor de ácidos gordos e OMEGA-3</p>
          </div>
          <div className="right-col">

          <button className="titulo sneak-regular " onClick={closeHandler3}>Fechar</button>
          </div>
        </div>
        <div className="body-modal">
           <p className="descricao sneak-regular">
           O salmão é uma das principais fontes de ácidos gordos ómega-3. Os ácidos gordos ómega-3 são
essenciais ao organismo, mas não podem ser sintetizados pelo mesmo, sendo necessária a sua
obtenção exclusivamente a partir da alimentação. A ingestão de ácidos gordos ómega-3,
nomeadamente ácido eicosapentaenóico (EPA) e ácido docosahexaenóico (DHA), está associada
com a redução do risco de doenças cardiovasculares, particularmente a doença arterial
coronária. Este benefício cardiovascular está principalmente relacionado com a:<br></br><br></br>
<b>• Diminuição do risco de incidências de arritmia;</b><br></br>
<b>• Melhoria na pressão arterial;</b><br></br>
<b>• Redução da progressão de aterosclerose;</b><br></br>
<b>• Redução dos níveis de triglicéridos.</b>
           <br></br><br></br>
           DHA é bastante importante no desenvolvimento e função cerebral, contribuindo
fundamentalmente para o desenvolvimento visual. A função destes ácidos gordos está também
associada à prevenção de doenças neurológicas como o Alzheimer e no tratamento de outras
como a depressão.
           </p>
        </div>
      </div>

    </div>


    <div className="wrapper-bg-salmao">
        <div className="center-col-bg">
        <div className="logo-brand">
        <img src={logonew} alt="" />

        </div>
        </div>
     
      </div>

    <div id="section-1-salmao">
      <div className="wrapper-container">
   
        
        {/* <div className="mobile-logo">
            <img src={logonew} alt="Logo" />
          </div> */}
          
        <div className="titulo absara blue-marine">
          <p> Hambúrguer de Salmão Chili-Lemon</p>
        </div>
          <div className="descricao sneak-regular opacity">
            <p> Uma explosão de sabor! Ao Salmão, juntámos o toque de chili e limão, para transformar as refeições simples do seu dia-a-dia! </p>
          </div>
      </div>
      <div className="container-video">
          <div className="left-col-video">
          <video className="video-class" preload='auto' controls autoPlay loop muted playsInline>
            <source src={StopMotion} type="video/mp4" />
          </video>

          </div>
          <div className="right-col-video">
            <div className="container-right-col">
            <p className="absara titulo blue-marine">Confeção</p>
            <p className="sneak-regular opacity ">Siga as instruções deste pequeno video para conseguir tirar o melhor partido destes maravilhosos hambúrgueres.</p>
            </div>
          </div>
      </div>
      <div id="caracts-section-salmao">
      <div className="caracts-wrapper">
        <div className="left-col">
          <div className="text-element">
            <div className="titulo absara blue-marine">
              Nutrição
            </div>
            <div className="value sneak-regular ">
            <button onClick={clickHandler}>

              <span className="span-class">Fonte de proteína</span></button><br></br>
              
              <button onClick={clickHandler2}><span className="span-class">Alto teor em vitamina D e B12</span></button><br></br>
              <button onClick={clickHandler3}><span className="span-class">Alto teor de ácidos gordos e OMEGA-3</span></button>

              
            </div>
          </div>

        </div>
        <div className="right-col">
        <div className="text-element">
            <div className="titulo absara blue-marine">
              Método de confeção
            </div>
            <div className="value">
              <div className="icon">
              <img src={salmaoicones} alt="Logo" />
              </div>
              <div className="value sneak-regular opacity white">
                <p>15 minutos no forno a temperatura de 180º Celsius</p>

              </div>
            

              
            </div>
            <div className="value">
              <div className="icon">
              <img src={salmaoicones2} alt="Logo" />
              </div>
              <div className="value sneak-regular opacity white">
                <p>5 minutos na frigideira</p>

              </div>
            

              
            </div>
          </div>
          
          </div>
      </div>
      </div>
    </div>
    <div className="wrapper-3-salmao">
    <div className="btn-div">
    <Link to="/" style={{ textDecoration: 'none' }}> <button className="btn sneak-bold">Descubra outros produtos!</button></Link>

          </div>
    </div>
  </Layout>
)

export default hamburguerBacalhau
